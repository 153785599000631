import React from "react";
import { withPrefix } from "gatsby";
import Masonry from "react-masonry-component";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { styled } from "@mui/material/styles";

const PostCard = styled(Card)(
  ({ theme }) => `
      max-width: 345px;
      margin-left: 15px;
      margin-top: 15px;
`
);

const PostListing = ({ postEdges }) => {
  const getPostList = () => {
    const postList = [];
    postEdges.forEach((postEdge) => {
      if (postEdge.node.frontmatter.categories.includes("Main")) {
        return;
      }

      postList.push({
        path: postEdge.node.fields.slug,
        tags: postEdge.node.frontmatter.tags,
        categories: postEdge.node.frontmatter.categories,
        cover: postEdge.node.frontmatter.cover,
        title: postEdge.node.frontmatter.title,
        date: postEdge.node.fields.date,
        excerpt: postEdge.node.excerpt,
        timeToRead: postEdge.node.timeToRead,
      });
    });
    return postList;
  };

  const postList = getPostList();

  return (
    <Masonry>
      {
        /* Your post list here. */
        postList.map((post) => (
          <PostCard key={post.path}>
            <CardActionArea href={withPrefix(post.path)}>
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  {post.title}
                </Typography>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  {post.date} &mdash; <span>{post.categories.join(" / ")}</span>{" "}
                  &mdash; {post.timeToRead} Min Read{" "}
                </Typography>
              </CardContent>
              {post.cover && (
                <GatsbyImage
                  image={getImage(post.cover)}
                  alt={post.title}
                  style={{ maxHeight: "164px" }}
                />
              )}
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {post.excerpt}
                </Typography>
              </CardContent>
            </CardActionArea>
          </PostCard>
        ))
      }
    </Masonry>
  );
};

export default PostListing;
